<template>
    <v-dialog max-width="500" v-model="ver" persistent>
        <v-card>
            <v-card-text>
                <p class="font-weight-bold text-h6 info--text text-center pt-3">Confirmar datos para la solicitud</p>

                <p v-if="Object.entries(itemDatosAdicionales).length !== 0">
                    <strong> Notaria: </strong> {{ itemDatosAdicionales.notario }} <br>
                    <strong>Repertorio: </strong> {{ escrituraEntity.repertorioByFechaEscritura(itemDatosAdicionales.numero_repertorio, itemDatosAdicionales.fecha_escritura) }}<br>
                    <strong>Fecha: </strong> {{ itemDatosAdicionales.fecha_escritura | toDate }} <br>
                    <strong>Contratantes: </strong> {{ escrituraEntity.concatenarContratantes(itemDatosAdicionales.contratantes) }} <br>
                </p>
              <v-alert outlined color="info" class="text-center mb-1">
                En caso de discrepancia entre los datos mencionados y otros que usted maneje, favor comunicarse a cotizaciones@cbrls.cl.
              </v-alert>
                <v-row class="py-0">
                    <v-col cols="12" md="6" class="pb-md-0">
                        <v-btn
                                @click="cancelarItem"
                                block
                                small
                                color="info"
                        >
                            Los datos no corresponden
                        </v-btn>
                    </v-col>

                    <v-col cols="12" md="6" class="pb-md-0">
                        <v-btn
                                @click="confirmarItem"
                                block
                                small
                                color="success"
                        >
                            Confirmar datos de escritura
                        </v-btn>
                    </v-col>
                </v-row>
            </v-card-text>
        </v-card>
    </v-dialog>
</template>

<script>
import ModalMixin from "@/mixins/ModalMixin";
import {escrituraEntity} from "@/model/escritura/escritura.entity";

export default {
    name: "ModalConfirmarDatosAdicionalesEscrituraPublica",
    computed: {
        escrituraEntity() {
            return escrituraEntity
        }
    },
    mixins: [ModalMixin],
    props: {
        itemDatosAdicionales: {
            type: Object,
            default: null
        }
    },
    methods: {
        confirmarItem() {
            this.$emit("confirmarItem", this.itemDatosAdicionales);
            this.hideDialog();
        },
        cancelarItem() {
            this.hideDialog();
        }
    }
}
</script>

<style scoped>

</style>