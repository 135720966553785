var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"max-width":"1150"},model:{value:(_vm.ver),callback:function ($$v) {_vm.ver=$$v},expression:"ver"}},[_c('v-card',[_c('h4',{staticClass:"mx-4 py-4"},[_vm._v("Escrituras encontradas")]),(_vm.tabla.length>0)?_c('div',{staticClass:"ml-4"},[_vm._v(" "+_vm._s(_vm.splitDatosConocidos[0]))]):_vm._e(),(_vm.tabla.length>0)?_c('div',{staticClass:"ml-4"},[_vm._v(" "+_vm._s(_vm.splitDatosConocidos[1]))]):_vm._e(),(_vm.cargandoTabla && _vm.tabla.length == 0)?_c('div',{staticClass:"px-4"},[_c('v-progress-linear',{staticClass:"my-4",attrs:{"color":"info accent-4","indeterminate":"","rounded":"","height":"6"}})],1):_vm._e(),(_vm.tabla.length == 0 && _vm.cargandoTabla==false)?_c('div',{staticClass:"pb-2"},[_c('v-alert',{staticClass:"text-center mx-4 pb-4",attrs:{"outlined":"","color":"info"}},[_vm._v("Sin datos encontrados")])],1):_vm._e(),_c('v-data-table',{directives:[{name:"show",rawName:"v-show",value:(_vm.tabla.length>0 && (_vm.radioSabeRepertorio==null || _vm.radioSabeRepertorio == false) ),expression:"tabla.length>0 && (radioSabeRepertorio==null || radioSabeRepertorio == false) "}],staticClass:"elevation-0 my-4 ",attrs:{"headers":_vm.headers,"items":_vm.tabla,"items-per-page":10},scopedSlots:_vm._u([{key:"body",fn:function(ref){
var items = ref.items;
return [_c('tbody',_vm._l((items),function(item){return _c('tr',{key:item.id},[_c('td',{staticClass:"text-center"},[_vm._v(_vm._s(_vm._f("toDate")(item.fecha_escritura)))]),_c('td',{staticClass:"text-center"},[_vm._v(_vm._s(item.ubicacion_propiedad))]),_c('td',{staticClass:"text-center"},[_vm._v(" "+_vm._s(item.comuna))]),_c('td',{staticClass:"text-center"},[_c('v-row',{},[_c('v-col',{staticClass:"ma-0 pa-0",attrs:{"cols":""}},[_c('v-tooltip',{attrs:{"bottom":"","color":"primary"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mr-2",attrs:{"color":"primary","dark":"","x-small":"","outlined":""}},'v-btn',attrs,false),on),[_vm._v(" Compradores ")])]}}],null,true)},_vm._l((item.contratantes.compradores),function(comprador){return _c('ul',{key:comprador.id_comprador},[_c('li',[_vm._v(_vm._s(comprador.nombre_completo))])])}),0)],1),_c('v-col',{staticClass:"ma-0 pa-0",attrs:{"cols":""}},[_c('v-tooltip',{attrs:{"bottom":"","color":"primary"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"primary","dark":"","outlined":"","x-small":""}},'v-btn',attrs,false),on),[_vm._v(" Vendedores. ")])]}}],null,true)},_vm._l((item.contratantes.vendedores),function(vendedor){return _c('ul',{key:vendedor.id_vendedor},[_c('li',[_vm._v(_vm._s(vendedor.nombre_completo))])])}),0)],1)],1)],1),_c('td',{staticClass:"text-center"},[_c('v-btn',{attrs:{"icon":"","color":"info"},on:{"click":function($event){return _vm.seleccionarElemento(item)}}},[_c('v-icon',[_vm._v("mdi-check-bold")])],1)],1)])}),0)]}}])}),_c('v-data-table',{directives:[{name:"show",rawName:"v-show",value:(_vm.tabla.length>0 && (_vm.radioSabeRepertorio && _vm.radioSabeRepertorio!==null)),expression:"tabla.length>0 && (radioSabeRepertorio && radioSabeRepertorio!==null)"}],staticClass:"elevation-0 my-4 ",attrs:{"headers":_vm.headersTabla2,"items":_vm.tabla,"items-per-page":10},scopedSlots:_vm._u([{key:"body",fn:function(ref){
var items = ref.items;
return [_c('tbody',_vm._l((items),function(item){return _c('tr',{key:item.id},[_c('td',{staticClass:"text-center"},[_vm._v(_vm._s(item.numero_repertorio)+"-"+_vm._s(_vm.retornaAnho(item.fecha_escritura)))]),_c('td',{staticClass:"text-center"},[_vm._v(_vm._s(item.ubicacion_propiedad))]),_c('td',{staticClass:"text-center"},[_vm._v(_vm._s(item.comuna))]),_c('td',[_c('v-row',{staticClass:"text-center"},[_c('v-col',{staticClass:"ma-0 pa-0",attrs:{"cols":""}},[_c('v-tooltip',{attrs:{"bottom":"","color":"primary"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mr-2",attrs:{"color":"primary","dark":"","x-small":"","outlined":""}},'v-btn',attrs,false),on),[_vm._v(" Compradores ")])]}}],null,true)},_vm._l((item.contratantes.compradores),function(comprador){return _c('ul',{key:comprador.id_comprador},[_c('li',[_vm._v(_vm._s(comprador.nombre_completo))])])}),0)],1),_c('v-col',{staticClass:"ma-0 pa-0",attrs:{"cols":""}},[_c('v-tooltip',{attrs:{"bottom":"","color":"primary"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"primary","dark":"","outlined":"","x-small":""}},'v-btn',attrs,false),on),[_vm._v(" Vendedores. ")])]}}],null,true)},_vm._l((item.contratantes.vendedores),function(vendedor){return _c('ul',{key:vendedor.id_vendedor},[_c('li',[_vm._v(_vm._s(vendedor.nombre_completo))])])}),0)],1)],1)],1),_c('td',{staticClass:"text-center"},[_c('v-btn',{attrs:{"icon":"","color":"info"},on:{"click":function($event){return _vm.seleccionarElemento(item)}}},[_c('v-icon',[_vm._v("mdi-check-bold")])],1)],1)])}),0)]}}])}),_c('div',{staticClass:"text-center pb-4"},[_c('v-btn',{attrs:{"color":"info","dark":""},on:{"click":function($event){return _vm.hideDialog()}}},[_c('v-icon',[_vm._v("mdi-arrow-left-bold")]),_vm._v(" Volver ")],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }