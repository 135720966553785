import {obtenerEscritura} from "@/model/escritura/usecases/obtener-escritura";
import {obtenerEscrituras} from "@/model/escritura/usecases/obtener-escrituras";
import concatenarContratantes from "@/model/escritura/usecases/concatenar-contratantes";
import repertorioByFechaEscritura from "@/model/escritura/usecases/repertorio-by-fecha-escritura";
import {validarFechasEscrituras} from "@/model/escritura/usecases/validar-fechas-escrituras";


export const escrituraEntity = {
    obtenerEscritura,
    obtenerEscrituras,
    concatenarContratantes,
    repertorioByFechaEscritura,
    validarFechasEscrituras
}