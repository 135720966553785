<template>
  <v-dialog max-width="1150" v-model="ver">
    <v-card>
      <h4 class="mx-4 py-4">Escrituras encontradas</h4>
      <div class="ml-4" v-if="tabla.length>0"> {{ splitDatosConocidos[0] }}</div>
      <div class="ml-4" v-if="tabla.length>0"> {{ splitDatosConocidos[1] }}</div>
      <div v-if="cargandoTabla && tabla.length == 0" class="px-4">
        <v-progress-linear
            color="info accent-4"
            class="my-4"
            indeterminate
            rounded
            height="6"
        ></v-progress-linear>
      </div>
      <div v-if="tabla.length == 0 && cargandoTabla==false" class="pb-2">
        <v-alert outlined color="info" class="text-center mx-4 pb-4">Sin datos encontrados</v-alert>
      </div>
      <v-data-table
          v-show="tabla.length>0 && (radioSabeRepertorio==null || radioSabeRepertorio == false) "
          :headers="headers"
          :items="tabla"
          :items-per-page="10"
          class="elevation-0 my-4 "
      >
        <template v-slot:body="{ items }">
          <tbody>
          <tr v-for="item in items" :key="item.id">
            <td class="text-center">{{ item.fecha_escritura | toDate }}</td>
            <td class="text-center">{{ item.ubicacion_propiedad }}</td>
            <td class="text-center"> {{ item.comuna }}</td>
            <td class="text-center">
              <v-row class="">
                <v-col cols="" class="ma-0 pa-0">
                  <v-tooltip bottom color="primary">
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                          color="primary"
                          dark
                          x-small
                          outlined
                          v-bind="attrs"
                          v-on="on"
                          class="mr-2"
                      >
                        Compradores
                      </v-btn>
                    </template>
                    <ul v-for="comprador in item.contratantes.compradores" :key="comprador.id_comprador">
                      <li>{{ comprador.nombre_completo }}</li>
                    </ul>
                  </v-tooltip>
                </v-col>
                <v-col cols="" class="ma-0 pa-0">
                  <v-tooltip bottom color="primary">
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                          color="primary"
                          dark
                          outlined
                          x-small
                          v-bind="attrs"
                          v-on="on"
                      >
                        Vendedores.
                      </v-btn>
                    </template>
                    <ul v-for="vendedor in item.contratantes.vendedores" :key="vendedor.id_vendedor">
                      <li>{{ vendedor.nombre_completo }}</li>
                    </ul>
                  </v-tooltip>
                </v-col>
              </v-row>
            </td>
            <td class="text-center">
              <v-btn
                  @click="seleccionarElemento(item)"
                  icon color="info">
                <v-icon>mdi-check-bold</v-icon>
              </v-btn>
            </td>
          </tr>
          </tbody>
        </template>
      </v-data-table>
      <v-data-table
          v-show="tabla.length>0 && (radioSabeRepertorio && radioSabeRepertorio!==null)"
          :headers="headersTabla2"
          :items="tabla"
          :items-per-page="10"
          class="elevation-0 my-4 "
      >
        <template v-slot:body="{ items }">
          <tbody>
          <tr v-for="item in items" :key="item.id">
            <td class="text-center">{{ item.numero_repertorio }}-{{retornaAnho(item.fecha_escritura)}}</td>
            <td class="text-center">{{ item.ubicacion_propiedad }}</td>
            <td class="text-center">{{ item.comuna }}</td>
            <td>
              <v-row class="text-center">
                <v-col cols="" class="ma-0 pa-0">
                  <v-tooltip bottom color="primary">
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                          color="primary"
                          dark
                          x-small
                          outlined
                          v-bind="attrs"
                          v-on="on"
                          class="mr-2"
                      >
                        Compradores
                      </v-btn>
                    </template>
                    <ul v-for="comprador in item.contratantes.compradores" :key="comprador.id_comprador">
                      <li>{{ comprador.nombre_completo }}</li>
                    </ul>
                  </v-tooltip>
                </v-col>
                <v-col cols="" class="ma-0 pa-0">
                  <v-tooltip bottom color="primary">
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                          color="primary"
                          dark
                          outlined
                          x-small
                          v-bind="attrs"
                          v-on="on"
                      >
                        Vendedores.
                      </v-btn>
                    </template>
                    <ul v-for="vendedor in item.contratantes.vendedores" :key="vendedor.id_vendedor">
                      <li>{{ vendedor.nombre_completo }}</li>
                    </ul>
                  </v-tooltip>
                </v-col>
              </v-row>
            </td>
            <td class="text-center">
              <v-btn
                  @click="seleccionarElemento(item)"
                  icon color="info">
                <v-icon>mdi-check-bold</v-icon>
              </v-btn>
            </td>
          </tr>
          </tbody>
        </template>
      </v-data-table>
      <div class="text-center pb-4">
        <v-btn color="info" dark @click="hideDialog()">
          <v-icon>mdi-arrow-left-bold</v-icon>
          Volver
        </v-btn>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
import {indexOf} from "core-js/internals/array-includes";

export default {
  name: "ModalTablaEscriturasBusqueda",
  props: {
    datosConocidos: {default: ''},
    radioSabeRepertorio: {default: false},
    escriturasEncontradas: {default: Array},
    verDialog: {default: false},
    time: {default: null},
    tipo: {
      default: "info"
    }
  },
  data() {
    return {
      cargandoTabla: true,
      tabla: [],
      ver: false,
      show: false,
      headers: [
        {text: 'Fecha', align: 'center', value: 'fecha_escritura',sortable: false},
        {text: 'Ubicación', align: 'center', value: 'ubicacion',sortable: false},
        {text: 'Comuna', align: 'center', value: 'ubicacion', width:'200',sortable: false},
        {text: 'Contratantes', align: 'center', width:'200',sortable: false},
        {text: 'Seleccionar', align: 'center',sortable: false}
      ],
      headersTabla2: [
        {text: 'N repertorio', align: 'center', value: 'numero_repertorio',sortable: false},
        {text: 'Ubicación', align: 'center', value: 'ubicacion',sortable: false},
        {text: 'Comuna', align: 'center', value: 'ubicacion', width:'200',sortable: false},
        {text: 'Contratantes', align: 'center', width:'200',sortable: false},
        {text: 'Seleccionar', align: 'center',sortable: false}
      ],
    }
  },
  computed: {
    splitDatosConocidos() {
      let splitDatos = this.datosConocidos.split('///')
      return splitDatos
    },

  },
  watch: {
    escriturasEncontradas() {
      this.tabla = []
      this.cargandoTabla = false
      this.tabla = [...this.escriturasEncontradas]
      console.log('cambiando array', this.escriturasEncontradas)
    },
    verDialog() {
      this.ver = this.verDialog;
      this.cargandoTabla = true
      this.tabla = []
      if (this.verDialog) {
        this.tabla = []
        console.log('holaaaa', this.escriturasEncontradas)
        this.tabla = [...this.escriturasEncontradas]
      } else {

        this.tabla = []
        console.log('holaaaa2', this.tabla)
      }
      if (this.time != null) {
        if (!isNaN(this.time)) {
          let autoclose = parseInt(this.time) * 1000;
          setTimeout(() => this.hideDialog(), autoclose)
        }

      }
    },

    ver(newVal) {
      if (!newVal) {
        this.$emit("ocultarDialog", false);
      }
    }
  },

  methods: {
    indexOf,
    retornaAnho(fecha){
      let anho = (new Date(fecha)).getFullYear();
      console.log(anho)
      return anho
    },
    seleccionarElemento(item) {
      console.log('elemento seleccionado', item)
      this.$emit("cargarSeleccionado", {
        repertorio:item.numero_repertorio +'-'+ (new Date(item.fecha_escritura)).getFullYear(),
        fecha:item.fecha_escritura
      });

      this.hideDialog()
    },
    hideDialog() {
      this.$emit("ocultarDialog", false);
    }
  }
}
</script>

<style scoped>

</style>