<template>
  <v-dialog max-width="500" v-model="ver" persistent>
    <v-card>
      <v-card-text>
        <p class="font-weight-bold text-h6 info--text text-center pt-3">Datos de escritura sin coincidencias</p>

        <p class="text-center">Según nuestros registros no encontramos una escritura que coincida con los datos ingresados. Si está seguro(a) que los datos son correctos podrá continuar y requerir una cotización para su posterior pago.</p>
        <div class="text-center">
          <v-btn @click="hideDialog" color="info" outlined>
            <v-icon left>mdi-close</v-icon>
            Volver
          </v-btn>
        </div>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "ModalMensaje",
  props: {
    time: {default: null},
    tipo: {default: "info"},
    verDialog: {default: false}
  },
  data() {
    return {
      ver: false,
      checkbox: false
    }
  },

  watch: {
    verDialog() {
      this.ver = this.verDialog;
    },

    ver(newVal) {
      if (!newVal) {
        this.checkbox = false;
        this.$emit("ocultarDialog", false);
      }
    }
  },

  methods: {
    hideDialog() {
      this.$emit("ocultarDialog", false);
    },
  }
}
</script>

<style scoped>

</style>
