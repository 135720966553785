const concatenarContratantes = (contratantesArray) => {
    let compradores = ''
    let vendedores = ' '
    contratantesArray[0].forEach((comprador, index) => {
        compradores = compradores + comprador.nombre_completo
        if (contratantesArray[0].length !== (index + 1)) {
            compradores = compradores + ', '
        } else if (contratantesArray[0].length === (index + 1)) {
            compradores = compradores + ' Y'
        }
        console.log(comprador)
        console.log(compradores)
        console.log('length ', contratantesArray[0].length, ' index', index)
    })
    contratantesArray[1].forEach((vendedor, index) => {
        vendedores = vendedores + vendedor.nombre_completo
        if(vendedor.nombre_completo.charAt(0) == 'i' ||  vendedor.nombre_completo.charAt(0) =='I'){
            console.log('reemplazar por e')
            compradores = compradores.substring(0, compradores.length - 1) + "E";
        }
        if (contratantesArray[0].length !== (index + 1)) {
            vendedores = vendedores + ', '
        }
        console.log(vendedor)
        console.log(vendedores)
        console.log('length ', contratantesArray[0].length, ' index', index)

    })
    console.log(compradores, vendedores)

    return compradores + vendedores;
}

export default concatenarContratantes;