import axios from "axios";
import qs from "qs";


export const obtenerEscrituraApi = (id_notaria_sii, numero_repertorio, fecha_escritura) => {
    return new Promise((resolve, reject) => {
        axios.post(
            "/escritura?action=obtener-escritura",
            qs.stringify({
                id_notaria_sii : id_notaria_sii,
                numero_repertorio : numero_repertorio,
                fecha_escritura : fecha_escritura,
            })
        ).then(function (response) {
            resolve(response);
        }).catch(function (error) {
            reject(error);
        });
    })
}


export const ObtenerEscrituras = (id_notaria_sii, numero_repertorio, fecha_escritura) => {
    return new Promise((resolve, reject) => {
        axios.post(
            "/escritura?action=get-lista-escritura",
            qs.stringify({
                id_notaria_sii : id_notaria_sii,
                numero_repertorio : numero_repertorio,
                fecha_escritura : fecha_escritura,
            })
        ).then(function (response) {
            resolve(response);
        }).catch(function (error) {
            reject(error);
        });
    })
}

export const validarFechasEscriturasApi = (id_notaria_sii, numero_repertorio, anho_repertorio) => {
    return new Promise((resolve, reject) => {
        axios.post(
            "/escritura?action=buscar-coincidencia-escritura",
            qs.stringify({
                id_notaria_sii : id_notaria_sii,
                numero_repertorio : numero_repertorio,
                anho_repertorio : anho_repertorio,
            })
        ).then(function (response) {
            resolve(response);
        }).catch(function (error) {
            reject(error);
        });
    })
}

