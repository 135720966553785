export default {
    props: {
        verDialog: {default: false},
    },
    data() {
        return {
            ver: false,
        };
    },
    watch: {
        verDialog() {
            this.ver = this.verDialog;
        },
        ver(newVal) {
            if (!newVal) {
                this.hideDialog();
            }
            else
            {
                this.modalShow();
            }
        },
    },
    methods: {
        hideDialog() {
            this.$emit("e-ocultar-dialog");
        },
        modalShow(){

        },
    }
};